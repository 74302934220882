<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.categoryName+' / '+detail.dictName+' / '+detail.itemName">
    <Tag size="medium" color="blue" slot="status">{{ detail.riskGrade|getBaseDataName('风险等级') }}</Tag>
    <template slot="brief">
      <ys-cell title="风险辨别" :label="String(detail.scope === -1 ? '-' : detail.scope)"/>
      <ys-cell title="发现时间" :label="detail.serveTime"/>
      <ys-cell title="提交专家" :label="detail.expertName"/>
    </template>
    <div slot="main-left">
      <h1 class="h1Title">详细信息</h1>
      <base-info :detail="detail"></base-info>
    </div>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <read-list :checkedData="detail" :midData="[detail]" v-if="detail.id"></read-list>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {getProblemsInfo} from "@/api/problem";
import config from '@/config/index'
import BaseInfo from "@/views/task/problems/baseInfo.vue";
import ReadList from "@/views/task/list/detail/rebackList";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {ReadList, BaseInfo},
  computed: {
    config: function () {
      return config
    }
  },
  mounted() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.loading = true;
      this.detail = {};
      getProblemsInfo({id: this.detailID}).then(res => {
        this.loading = false;
        if (res.code === 200) {
          this.detail = res.data;
        }
      })
    }
  }
}
</script>

<style scoped>

</style>