<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="/comment/List"
        :params="params"
        :tableHeight="tableHeight"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="风险等级" prop="riskGrade">
          <Select v-model="params.riskGrade" clearable placeholder="风险等级" style="width: 100px;">
            <Option v-for="(item) in baseData['风险等级']" :style="{'color':item.color }" :value="item.value" :key="item.value">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="发现日期" prop="daterange">
          <ys-date-picker type="datetimerange" v-model="params.daterange" clearable placeholder="发现日期"></ys-date-picker>
        </FormItem>
        <FormItem label="关键词" prop="keyword" show>
          <Input placeholder="项目名称" clearable style="width: 200px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <Detail :mid="detailID"></Detail>
      </template>
      <template slot="btn">
        <ButtonGroup>
          <Button type="primary" @click="add">新增</Button>
        </ButtonGroup>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :width="1200" :title="(tableSelectItem.length>0?'修改':'新增')+'风险'">
      <Edit @on-ok="getList" :parentID="mid" :midData="midData.length > 0 ? midData : tableSelectItem" :mid="detailID"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import Detail from "@/views/task/problems/detail.vue";
import Edit from "@/views/task/problems/Edit.vue";

export default {
  name: 'taskProblems',
  mixins: [pageList],
  components: {Detail, Edit},
  props: {
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      params: {
        checkId: '',
        dictId: '',
        itemId: '',
        amendId: '',
        riskGrade: '',
        daterange: [],
        startAt: '',
        endAt: '',
      },
      putInFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 200, title: '项目名称', key: 'projectName', align: 'left',},
        {width: 200, title: '风险项', key: 'itemName', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: '检查专家', key: 'expertName', align: 'center'},
        {
          width: 100, title: '扣除分值', key: 'scope', align: 'center', render: (h, params) => {
            return h('span', String(params.row.scope === -1 ? '-' : params.row.scope))
          }
        },
        {width: 100, title: '风险等级', key: 'riskGrade', align: 'center', renderConfig: {type: 'baseData', parentName: '风险等级'}},
        {minWidth: 230, title: '风险描述', tooltip: true, key: 'remarksText', align: 'left'},
        {minWidth: 230, title: '风险依据', tooltip: true, key: 'remarksRisk', align: 'left'},
        {minWidth: 230, title: '可导致事故类型', tooltip: true, key: 'accidentType', align: 'left'},
        {minWidth: 230, title: '整改建议', tooltip: true, key: 'remarksSuggest', align: 'left'},
        {width: 100, title: '隐患状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '隐患状态'}},
        {width: 170, title: '发现时间', key: 'serveTime', align: 'center'},
        {width: 170, title: '录入时间', key: 'createdAt', align: 'center'},
        {width: 170, title: '保险公司', key: 'companyName', align: 'center'},
        {width: 180, title: '区域', key: 'countyCode ', align: 'left', renderConfig: {type: 'area', key: ['countyCode', 'cityCode', 'provinceCode']}},
      ]
    }
  },
  computed: {
    multiBtn() {
      return [
        {
          children: [
            {
              type: 'detail',
              title: '查看详情',
              icon: 'md-eye',
              disabled: this.tableSelectItem.length !== 1
            },
            {
              click: () => this.modShow = true,
              title: '修改',
              icon: 'md-create',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status > 3
            },
            {
              click: () => this.delItem('/comment/Remove', {id: this.detailID}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status > 3
            },
          ]
        }
      ]
    },
  },
  mounted() {
    this.params.checkId = this.mid;
    this.getList();
  },
  methods: {},
  watch: {
    'params.daterange'() {
      this.params.startAt = this.params.daterange[0] || ''
      if(this.params.daterange[1] && this.params.daterange[1].split(' ')[1] === '00:00:00') {
        this.params.daterange[1] = this.params.daterange[1].split(' ')[0] + ' ' + '23:59:59'
      }
      this.params.endAt = this.params.daterange[1] || ''
    }
  }
}
</script>
