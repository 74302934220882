import {get,post} from 'ys-admin'


/**
 *  获取任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
export const getProblemsList = function (params) {
  return get('/comment/List', params)
};

/**
 *  获取专项任务检查风险详情
 * @param {Object} params
 * @returns {Object}
 */
export const getProblemsInfo = function (params) {
  return get('/comment/GetInfo', params)
};


/**
 * 提交整改单
 * @param {Object} params
 * @returns {Object}
 */
export const amendCommit = function (params) {
  return post('/amend/Commit', params)
};