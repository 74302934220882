import {post, get} from 'ys-admin'

/**
 *  获取报告信息
 * @param {Object} params
 * @returns {Object}
 */
export const getReportInfo = function (params) {
  return get('/report/GetInfo', params)
};
/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const reportCommitCheck = function (params) {
  return post('/report/CommitCheck', params)
};
/**
 *  获取报告信息
 * @param {Object} params
 * @returns {Object}
 */
export const getReportScoreInfo = function (params) {
  return get('/report/GetScore', params)
};
/**
 *  获取报告详情
 * @param {Object} params
 * @returns {Object}
 */
export const getReportDetail = function (params) {
  return get('/report/GenInfo', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const addReport = function (params) {
  return post('/report/Add', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const reportCommit = function (params) {
  return post('/report/Commit', params)
};

/**
 *  新增/修改报告
 * @param {Object} params
 * @returns {Object}
 */
export const reportEdit = function (params) {
  return post('/report/Add', params)
};
