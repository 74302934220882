<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="70">
      <FormItem label="打卡时间" prop="time">
        <ys-date-picker type="datetime" v-model="form.time" style="width: 100%;" placeholder="打卡时间"></ys-date-picker>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/checkUser/EditSignTime')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: 'status',
  mixins: [edit],
  props: {
    status: {type: Number, default: null}
  },
  data() {
    return {
      form: {
        checkId: '',
        userId: '',
        time: '',
      }
    }
  },
  mounted() {
    if (this.midData.length === 1) {
      this.form.checkId = this.midData[0].checkId;
      this.form.userId = this.midData[0].userId;
      this.form.time = this.midData[0].signTime;
    }

  },
  methods: {}
}
</script>
<style lang="less" scoped>
</style>
