<template>
  <div class="home">
    <ys-page-list
        ref="table"
        :tableHeight="height-260"
        @on-selection-change="checkItem"
        :multiColumn="false"
        :footer="false"
        :header="false"
        :isEmptySelect="isEmptySelect"
        :headerColumns="headerColumns"
        :tableData="dataList">
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import components from '@/mixins/components.js';
import {quickInfoList} from '@/api/common'
import {getDictItemInfo} from "@/api/task";

export default {
  name: 'quickInfo',
  mixins: [pageList, components],
  props: {
    orgId: {
      type: [String, Number],
      default: ''
    },
    itemId: {
      type: [String, Number],
      default: ''
    },
    type: {
      type: [String, Number],
      default: ''
    },
    status: {
      type: [String, Number],
      default: ''
    },
    keyword: {
      type: [String, Number],
      default: ''
    },
  },
  components: {},
  data() {
    return {
      params: {
        orgId: '',
        type: '',
        status: '',
        isTop: false,
        keyword: '',
      },
      headerColumns: [
        {width: 50, type: 'selection', title: '选择框', align: 'center'},
        {width: 50, type: 'index', title: '序号', align: 'center'},
        {minWidth: 500, title: '快捷信息内容', key: 'desc', align: 'left',  },
        {width: 100, title: '来源', key: 'source', align: 'center'},
      ],
      dataList: []
    }
  },
  computed: {},
  watch: {},
  mounted() {
    this.params.orgId = this.orgId
    this.params.type = this.type
    this.params.status = this.status
    this.params.keyword = this.keyword
    if(this.type === 1) { // 新增/修改隐患
      this.getInfo()
    } else { // 新增/修改报告
      this.getQuickInfoList()
    }
  },
  methods: {
    getInfo() {
      this.dataList = []
      this.$all([getDictItemInfo({itemId: this.itemId}), quickInfoList({...this.params})]).then(res => {
        // 筛选出非空以及去重后的数据，并重构数据格式，后合并到列表中
        let list = (res[0].data || []).filter((item, index) => {
          return res[0].data.findIndex(ite => ite.remarksSuggest === item.remarksSuggest) === index && item.remarksSuggest
        }).map(item => {
          return {
            id: item.id,
            source: '检查标准',
            desc: item.remarksSuggest
          }
        })
        // 若当前任务所属的保单未分配服务机构或其他清空导致携带的服务机构id为0了，这时候就会把所有的快捷信息数据都会拿出来了这时候直接为赋值空数据即可
        this.dataList = this.params.orgId ? (res[1].data?.list.map(item => {
          item.source = '快捷信息'
          return item
        }) || []) : []
        this.dataList.push(...list)
      })
    },
    getQuickInfoList() {
      this.dataList = []
      quickInfoList({orgId: this.orgId, type: 2, status: 1, keyword: '', isTop: false,limit: -1}).then(res => {
        // 默认整改建议
        const defaultList = [
          {
            id: 1,
            desc: '对隐患排查提出的安全风险及时整改。',
            source: '默认数据'
          }, {
            id: 2,
            desc: '加强人员进入施工现场按要求正确使用 PPE 防护用具教育宣导。',
            source: '默认数据'
          }, {
            id: 3,
            desc: '工程进入装饰安装扫尾阶段，加强现场消防安全管理，可燃物及时清理，焊接作业开具作业申请单，设置看火人，作业下方设置接火盆，合理配备灭火器材，可燃材料用防火布覆盖，防止火灾发生。',
            source: '默认数据'
          }, {
            id: 4,
            desc: '施工现场活动脚手架的使用量较大，建议总包单位组织专项检查，超过 2 米的架体要经过验收合格后使用，保证架体稳定，连接点销轴固定，作业时脚轮锁牢，人员上下设置爬梯，作业平台钢网脚手板两端锁定，设置作业护栏，系挂好安全带，毕业时设置剪刀撑、斜撑加以固定。',
            source: '默认数据'
          }, {
            id: 5,
            desc: '高处作业施工吊篮要经政府安监部门验收合格批准后方可使用，操作人员经过培训考核合格方可上岗作业，使用前要进行升降等安全试验，吊篮内作业人员不得超两个人，安全带锁扣按要求扣在安全绳上，安全绳单独固定在屋面牢固可靠位置不得与吊篮部位有任何连接，作业前检查悬挂机构、安全装置，钢丝绳等，特别是安全锁、上行程限位开关要定期维护检查。',
            source: '默认数据'
          }, {
            id: 6,
            desc: '安排专业电工排查现场所有施工用电设备和电箱，不符合规范要求的及时进行整改，禁止一闸多接、用电设备需经开关箱接电。',
            source: '默认数据'
          }, {
            id: 7,
            desc: '对现场存在的重大危险源进行管控，作业前进行危险源识别并制定相关措施，具体落实到个人。',
            source: '默认数据'
          },
        ]
        if(res.data?.list && res.data.list.length > 0) {
          this.dataList = res.data?.list.map(item => {
            item.source = '快捷信息'
            return item
          }) || defaultList
        } else {
          this.dataList = defaultList
        }
      })
    },
  }

}
</script>
