<template>
  <div class="reback" style="height:calc(100vh - 190px);overflow-y: scroll">
    <ys-cell-group class="baseInfo" :gutter="2">
      <ys-cell title="项目名称" :label="detail.projectName"/>
      <ys-cell title="保单号" :label="detail.policyNumber"/>
      <ys-cell title="所选风险项目" :label="detail.categoryName+' / '+detail.dictName+' / '+detail.itemName"></ys-cell>
      <ys-cell title="风险等级" :label="detail.riskGrade|getBaseDataName('风险等级')"/>
      <ys-cell title="发现时间" :label="detail.serveTime|formatDate('yyyy-MM-dd')"/>
      <ys-cell title="检查专家" :label="detail.expertName"/>
      <br/>
      <ys-cell title="整改期限" :label="detail.reformTerm|getBaseDataName('整改期限')"/>
      <ys-cell title="风险状态" :label="detail.status|getBaseDataName('隐患状态')"/>
      <ys-cell title="整改截止日" :label="detail.reformDate|formatDate('yyyy-MM-dd')"/>
      <ys-cell block title="扣分项" :label="JSON.parse(detail.itemDetailList||'[]').map(item=>item.desc).join('，')"/>
      <ys-cell block title="风险描述" :label="detail.remarksText"/>
      <ys-cell block title="风险依据" :label="detail.remarksRisk"/>
      <ys-cell title="可导致事故类型" :label="detail.accidentType" style="width: 100%"/>
      <ys-cell block title="整改建议" :label="detail.remarksSuggest"/>
    </ys-cell-group>
    <div>
      <h1 class="h1Title" style="display: block">风险图片</h1>
      <div v-viewer="{url: 'data-src'}">
        <ys-image
            :radius="3"
            v-for="subItem in (detail.fileList||[])"
            :key="subItem.id"
            :src="config.baseURL + '/resource/GetFile/'+subItem.id+'?size=100'"
            :data-src="config.baseURL + '/resource/GetFile/'+subItem.id"
            :width="100"
            :height="100"/>
      </div>
    </div>
  </div>
</template>

<script>
import config from '@/config/index'
import {mapGetters} from "vuex";

export default {
  name: "baseInfo",
  computed: {
    ...mapGetters(['height']),
    config() {
      return config;
    }
  },
  props: {
    detail: {
      type: Object, default: () => {
        return {}
      }
    }
  }
}
</script>

<style scoped lang="less">

</style>