import {get,post} from 'ys-admin'


/**
 * 保单详情
 * @param {Object} params
 * @returns {Object}
 */
export const policyInfo = function (params) {
  return get('/policy/Info', params)
};
/**
 * 保单详情
 * @param {Object} params
 * @returns {Object}
 */
export const multiAddPolicy = function (params) {
  return post('/policy/MultiAddPolicy', params)
};