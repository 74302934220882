<template>
  <ys-detail :icon="detailIcon" :loading="loading" :name="detail.iname">
    <Tag size="medium" color="blue" slot="status">{{ detail.status|getBaseDataName('专项任务状态') }}</Tag>
    <template slot="brief">
      <ys-cell title="发布开始" :label="detail.publishAt"/>
      <ys-cell title="完成期限" :label="detail.finishAt"/>
      <ys-cell title="任务描述" :label="detail.remarks"/>
      <ys-cell title="项目名称" :label="detail.projectName"/>
    </template>
    <template slot="main-left">
      <Tabs v-model="tab" name="detail" type="card">
        <Tab-pane label="风险列表" name="1" icon="md-list-box">
          <!-- <problems :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='1'"></problems> -->
          <problems :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='1'&&detail.type!==9"></problems>
          <risk :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='1'&&detail.type===9"></risk>
        </Tab-pane>
        <Tab-pane label="处理专家" name="2" icon="ios-contacts">
          <experts :type="1" :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='2'"></experts>
        </Tab-pane>
        <Tab-pane label="陪同业务员" name="3" icon="ios-contact">
          <experts :type="2" :tableHeight="pageListHeight" :midData="[detail]" :mid="detailID" v-if="tab==='3'"></experts>
        </Tab-pane>
        <Tab-pane label="检查报告" icon="md-chatbubbles" name="5">
          <template v-if="!loading&&tab==='5'">
            <ys-file-review :file-list="resultFileList" v-if="resultFileList.length > 0"></ys-file-review>
            <ys-empty v-else/>
          </template>
        </Tab-pane>
        <Tab-pane label="检查项目" name="4" icon="ios-browsers">
          <checkList :mid="detailID" v-if="tab==='4'"></checkList>
        </Tab-pane>
        <Tab-pane label="相关附件" name="6" icon="md-document">
          <ys-resource :resourceType="2" :tableHeight="pageListHeight" :mid="detailID" v-if="tab==='6'"></ys-resource>
        </Tab-pane>
      </Tabs>
    </template>
    <template slot="main-right">
      <h1 class="h1Title">其他信息</h1>
      <div style="text-align: center">暂未开放！</div>
    </template>
  </ys-detail>
</template>

<script>
import detailMixins from '@/mixins/detail'
import {getCheckInfo} from "@/api/task";
import problems from '@/views/task/list/detail/problems.vue'
import risk from "@/views/task/list/detail/risk/index";
import experts from '@/views/task/list/detail/experts.vue'
import checkList from '@/views/task/list/detail/checkList.vue'
import {getReportInfo} from "@/api/report";

export default {
  name: "detail",
  mixins: [detailMixins],
  components: {experts, problems, checkList, risk},
  mounted() {
    this.getDetail();
  },
  computed: {
    resultFileList() {
      let fileList = [];
      if (this.detail.report?.fileList?.length > 0) {
        fileList = fileList.concat(this.detail.report.fileList)
      }
      return fileList;
    }
  },
  methods: {
    async getDetail() {
      this.loading = true;
      await getCheckInfo({id: this.detailID}).then(res => {
        if (res.code === 200) {
          this.detail = res.data;
        }
        this.setTagName(this.detail.number)
      })
      if (this.detail.reportId > 0) {
        await getReportInfo({id: this.detail.reportId}).then((report) => {
          if (report.code === 200) {
            this.detail.report = report.data || {}
          }
        })
      }
      this.loading = false;
    }
  }
}
</script>

<style scoped>

</style>