<template>
  <div>
    <ys-page-list
        ref="table"
        action="expert/List"
        :IDs="IDs"
        :multiColumn="false"
        :params="params"
        :tableHeight="height-260"
        :isEmptySelect="isEmptySelect"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import components from '@/mixins/components';

export default {
  name: 'pro-component',
  mixins: [pageList, components],
  props: {
    IDs: {type: Array, default: () => []},
    status: {type: String, default: ''}
  },
  computed: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 75, title: 'logo', key: 'pictureId', align: 'center', renderConfig: {type: 'image'}},
        {minWidth: 230, title: '姓名',  key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 130, title: '联系电话', key: 'phone', align: 'left'},
        {width: 180, title: '身份证号码', key: 'identityCode', align: 'left'},
        {width: 150, title: '职称',  key: 'position', align: 'left'},
        {width: 200, title: '专业方向', key: 'territory', align: 'left'},
        {width: 130, title: '开始工作日期', key: 'workStart', align: 'center'},
        {width: 100, title: '工作年限', key: 'workYears', align: 'center'},
        {width: 80, title: '专家状态',  key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专家状态'}},
      ],
      params: {
        status: '',
        keyword: '',
      },
    }
  },
  mounted() {
    this.params.status = this.status
    this.getList();
  },
  methods: {}
}
</script>
