<template>
  <div>
    <ys-page-list
        ref="table"
        action="/checkUser/List"
        keyLabel="userId"
        :multiBtn="multiBtn"
        :tableHeight="tableHeight"
        :params="params"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="关键词" clearable style="width: 150px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="btn">
        <!-- 在任务检查中以及之前都可添加 -->
        <Button :disabled="midData[0].status > 5" type="primary" @click="add">新增</Button>
      </template>
    </ys-page-list>
    <ys-modal v-model="modShow" :title="type === 1 ? '新增专家' : '新增业务员'">
      <do-experts :type="type" :midData="midData" @on-ok="getList"></do-experts>
    </ys-modal>
    <ys-modal v-model="editSignTime" title="更新打卡时间">
      <sigTime :midData="tableSelectItem" @on-ok="getList"></sigTime>
    </ys-modal>
    <ys-modal v-model="signFlag" title="打卡">
      <sign :midData="tableSelectItem" @on-ok="getList"></sign>
    </ys-modal>
    <ys-modal v-model="smartDeviceFlag" :width="1000" title="绑定智能设备">
      <smartDevice @on-ok="getSmartDevice"></smartDevice>
    </ys-modal>
    <ys-drawer v-model="usageRecordDetailFlag" mask :width="90" title="项目列表">
      <usageRecordDetail :seqNo="tableSelectItem.length === 1 ? tableSelectItem[0].hatSeqNo : null" :table-height="height-100" @on-ok="getSmartDevice" #detail></usageRecordDetail>
    </ys-drawer>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js'
import doExperts from './doExperts'
import sigTime from '@/views/sign/signTime'
import sign from '@/views/sign/sign'
import smartDevice from '_c/smartDevice'
import {hardHatBindCheckUserBySeqNo} from "@/api/base";
import usageRecordDetail from "@/views/base/smartDevice/detail/usageRecord/detail";

export default {
  name: 'projects',
  mixins: [pageList],
  props: {type: {type: Number, default: 1}},
  components: {
    sign,
    doExperts,
    sigTime,
    smartDevice,
    usageRecordDetail
  },
  data() {
    return {
      params: {
        checkId: '',
        type: 1,
      },
      signFlag: false,
      editSignTime: false,
      putInFlag: false,
      smartDeviceFlag: false,
      usageRecordDetailFlag: false,
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 150, title: '姓名', key: 'iname', align: 'center'},
        {width: 150, title: '电话', key: 'phone', align: 'left',},
        {width: 100, title: '是否组长', key: 'isLeader', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 100, title: '是否确认', key: 'isConfirm', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {width: 100, title: '是否签到', key: 'isSign', align: 'center', renderConfig: {type: 'baseData', parentName: '是否'}},
        {minWidth: 200, title: '签到时间', key: 'signTime', align: 'left'},
        {minWidth: 250, title: '签到地点', key: 'address', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {width: 180, title: '签出时间', key: 'signOutTime', align: 'center'},
        {width: 150, title: '电话', key: 'phone', align: 'center'},
      ]
    }
  },
  computed: {
    multiBtn() {
      const {tableSelectItem = []} = this;
      return [
        {
          children: [
            {
              click: () => this.signFlag = true,
              title: '打卡',
              icon: 'md-stopwatch',
              disabled: tableSelectItem.length !== 1 || this.midData[0].status <= 2 || tableSelectItem[0].isSign === 1,
              show: this.type === 1
            },
            // isConfirm：是否确认，0未确认，1已确认
            // isLeader：是否是领导者
            // isSign：是否打卡，0未打卡，1已打卡
            {
              // 专家在未打卡之前都可以删除
              click: () => this.delItem(this.type === 1 ? '/check/expert/Remove' : '/check/user/RemoveInsUser', {checkId: tableSelectItem[0].checkId, expertId: tableSelectItem[0].expertId, userId: tableSelectItem[0].userId}),
              iconColor: '#ed4014',
              title: '删除',
              icon: 'md-trash',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].isSign === 1
            },
            {
              // 专家在未打卡之前都可以删除
              click: () => this.editSignTime = true,
              iconColor: '#ed4014',
              title: '更改打卡时间',
              icon: 'ios-clock-outline',
              disabled: tableSelectItem.length !== 1 || tableSelectItem[0].isSign === 0
            },
          ]
        },
        {
          children: [
            // {
            //   click: () => this.smartDeviceFlag = true,
            //   title: '绑定智能设备',
            //   icon: 'ios-radio-outline',
            //   disabled: tableSelectItem.length !== 1 || this.midData[0].status <= 2 || tableSelectItem[0].isSign === 1,
            // },
            {
              click: () => this.usageRecordDetailFlag = true,
              title: '智能设备使用情况',
              icon: 'ios-radio-outline',
              disabled: tableSelectItem.length !== 1 || !tableSelectItem[0].hatSeqNo,
            },
          ]
        },
      ]
    },
  },

  mounted() {
    this.params.type = this.type;
    this.params.checkId = this.mid;
    this.getList();
  },
  methods: {
    getSmartDevice(data) {
      const {checkId, expertId} = this.tableSelectItem[0]
      hardHatBindCheckUserBySeqNo({seqNo: data[0].seqNo, checkId, expertId}).then(res => {
        if (res.code === 200) {
          this.$Notice.success({
            title: '提示',
            desc: '操作成功！'
          });
          this.getList();
        }
      })
    }
  
  }
}
</script>
