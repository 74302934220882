<template>
  <div v-if="checkData">
    <Alert style="margin: 10px;">当前总分：{{ checkData.score.toFixed(2) }}分</Alert>
    <div class="checkListBox">
      <Anchor :affix="false" show-ink container=".auditContent_right" class="auditContent_left">
        <AnchorLink :href="'#'+item.id" v-for="(item) in (checkData.categoryList||[]).filter(item=>item.enable===1)" :key="item.id" :title="item.name+'('+item.score+'分)'"/>
      </Anchor>
      <div class="auditContent_right" :style="{height:height-240+'px'}">
        <template v-for="(item) in (checkData.categoryList || []).filter(item => item.enable === 1)">
          <table cellpadding="0" cellspacing="1" border="0" width="100%" :id="item.id" class="baseTable" v-for="(subItem) in item.children" :key="subItem.id">
            <tr>
              <th colspan="3" align="center">{{ subItem.name }}</th>
            </tr>
            <tr>
              <th width="50%">检查项名称</th>
              <th width="25%">总分数</th>
              <th width="25%">当前分数</th>
            </tr>
            <tr v-for="(netItem) in subItem.children" :key="netItem.id">
              <td align="center" :style="{'background-color':netItem.totalScore!==netItem.score?'#ffeee0':'' }">{{ netItem.name }}</td>
              <td align="center" :style="{'background-color':netItem.totalScore!==netItem.score?'#ffeee0':'' }">{{ netItem.totalScore }}分</td>
              <td align="center" :style="{'background-color':netItem.totalScore!==netItem.score?'#ffeee0':'' }">{{ netItem.score }}分</td>
            </tr>
          </table>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {getReportScoreInfo} from "@/api/report";
import {mapGetters} from "vuex";

export default {
  name: 'checkList',
  data() {
    return {
      checkData: null
    }
  },
  props: {
    mid: {type: [String, Number], default: 0},
  },
  computed: {
    ...mapGetters(["height"])
  },

  mounted() {
    getReportScoreInfo({checkId: this.mid}).then((res) => {
      if (res.code === 200) {
        this.checkData = res.data;
      }
    })
  }
}
</script>

<style scoped lang="less">
.checkListBox {
  display: flex;

  .auditContent_left {
    width: 220px;
    padding-left: 10px;
  }

  .auditContent_right {
    flex: 1;
    margin-left: 20px;
    height: 600px;
    overflow-y: scroll;
  }
}
</style>