<template>
  <div>
    <Form ref="form" class="editForm" :model="form" :rules="ruleValidate" :label-width="90">
      <FormItem :label="type === 1 ? '专家姓名' : '业务员姓名'">
        <Input disabled v-model="form.expertName" :placeholder="type === 1 ? '专家姓名' : '业务员姓名'">
          <Button slot="append" @click="expertFlag=true">选择</Button>
        </Input>
      </FormItem>
      <FormItem label="本次费用" v-if="type === 1">
        <InputNumber v-model="form.cost" placeholder="本次费用" :min="0" style="width: 100%"/>
      </FormItem>
      <FormItem label="组长" v-if="type === 1">
        <i-switch v-model="form.isLeader" :true-value="1" :false-value="0">
          <span slot="open">是</span>
          <span slot="close">否</span>
        </i-switch>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok(type === 1 ? '/check/expert/AddExpert' : '/check/user/AddInsUser')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
    <ys-modal v-model="expertFlag" :width="1200" :title="type === 1 ? '选择专家' : '选择业务员'">
      <ExpertsComponent v-if="type === 1" @on-ok="getData" :limit="1"></ExpertsComponent>
      <myUser v-else :companyId="companyId" @on-ok="getData"></myUser>
    </ys-modal>
  </div>
</template>

<script>
import edit from '@/mixins/edit'
import ExpertsComponent from "_c/experts";
import {policyInfo} from "@/api/policy";

export default {
  name: '',
  mixins: [edit],
  props: {
    type: {
      type: Number,
      default: 1
    }
  },
  components: {
    ExpertsComponent,
    'myUser': () => import('@/components/user.vue')
  },
  data() {
    return {
      form: {
        id: '',
        expertName: '',
        expertId: '',
        cost: null,
        isLeader: 0,
        userId: '',
      },
      expertFlag: false,
      companyId: '',
    }
  },
  async mounted() {
    if (this.type === 2) await this.getDetail()
  },
  methods: {
    async getDetail() {
      await policyInfo({id: this.midData[0].policyId}).then(res => {
        if (res.code === 200) {
          this.companyId = res.data.companyId;
        }
      })
    },
    getData(data) {
      this.form.id = this.midData[0].id
      switch(this.type) {
        case 1:
          this.form.expertName = data[0].iname
          this.form.expertId = data[0].id
          this.form.cost = null
          this.form.isLeader = 0
          break;
        case 2:
          this.form.userId = data[0].id
          this.form.expertName = data[0].iname
          break;
      }
    }
  }
}
</script>
<style scoped>

</style>
