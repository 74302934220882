import {get, post} from 'ys-admin'

/**
 * 获取任务详情
 * @param {Object} params
 * @returns {Object}
 */
export const getCheckInfo = function (params) {
  return get('/check/GetInfo', params)
};

/**
 * 安责险任务分配专家
 * @param {Object} params
 * @returns {Object}
 */
export const publicTask = function (params) {
  return post('/check/SelectExpert', params)
};

/**
 * 整改单详情
 * @param {Object} params
 * @returns {Object}
 */
export const getAmendInfo = function (params) {
  return get('/amend/GetInfo', params)
};

/**
 * 查询风险回复记录
 * @param {Object} params
 * @returns {Object}
 */
export const getProblemsRebackList = function (params) {
  return get('/amend/comment/reply/List', params)
};

/**
 * 查询报告模板数据
 * @param {Object} params
 * @returns {Object}
 */
export const templateList = function (params) {
  return get('/template/List', params)
};

/**
 * 任务专家打卡
 * @param {Object} params
 * @returns {Object}
 */
 export const expertSignIn = function (params) {
  return post('/check/SignIn', params)
};
/**
 * 任务专家打卡
 * @param {Object} params
 * @returns {Object}
 */
 export const checkConfirm = function (params) {
  return post('/check/Confirm', params)
};


/**
 *  获取专项任务扣分明细列表
 * @param {Object} params
 * @returns {Object}
 */
export const getDictItemInfo = function (params) {
  return get('/check/dict/detail/List', params)
};
/**
 *  获取专项任务依据明细列表
 * @param {Object} params
 * @returns {Object}
 */
export const getReasonItemInfo = function (params) {
  return get('/check/dict/reason/List', params)
};

/**
 *  获取专项任务检查内容
 * @param {Object} params
 * @returns {Object}
 */
export const checkDictGetInfo = function (params) {
  return get('/check/dict/GetInfo', params)
};