<template>
  <div>
    <ys-page-list
        ref="table"
        action="/hardHat/List"
        :IDs="IDs"
        :multiColumn="false"
        :params="params"
        :tableHeight="height-260"
        :isEmptySelect="isEmptySelect"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="设备类型" prop="type">
          <Select v-model="params.type" clearable placeholder="设备类型" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备类型']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="设备状态" prop="state">
          <Select v-model="params.state" clearable placeholder="设备状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="启停状态" prop="enabledState">
          <Select v-model="params.enabledState" clearable placeholder="启停状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备其他状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="设备使用状态" prop="useState" show>
          <Select v-model="params.useState" clearable placeholder="设备使用状态" style="width: 120px">
            <Option v-for="(item,index) in baseData['设备使用状态']" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="名称" prop="name" show>
          <Input placeholder="名称" clearable style="width: 150px;" v-model="params.name"></Input>
        </FormItem>
      </template>
    </ys-page-list>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok">确定</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList';
import components from '@/mixins/components';

export default {
  name: 'pro-component',
  mixins: [pageList, components],
  props: {
    IDs: {type: Array, default: () => []},
  },
  computed: {},
  data() {
    return {
      headerColumns: [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 50, align: 'center'},
        {width: 150, title: '名称', key: 'name', align: 'center', renderConfig: {type: 'gotoDetail'}},
        {width: 100, title: '设备类型', key: 'type', align: 'center', renderConfig: {type: 'baseData', parentName: '设备类型'}},
        {width: 100, title: '供应商', key: 'operator', align: 'center'},
        {width: 150, title: '设备序列号', key: 'seqNo', align: 'left',},
        // {width: 100, title: '设备参数', key: '', align: 'center'},
        {width: 150, title: '所属机构', key: 'orgName', align: 'left',},
        {width: 120, title: '设备状态', key: 'state', align: 'center', renderConfig: {type: 'baseData', parentName: '设备状态'}},
        {width: 120, title: '启停状态', key: 'enabledState', align: 'center', renderConfig: {type: 'baseData', parentName: '设备其他状态'}},
        {width: 120, title: '设备使用状态', key: 'useState', align: 'center', renderConfig: {type: 'baseData', parentName: '设备使用状态'}},
        {minWidth: 200, title: '备注', key: 'des', align: 'left',},
        {width: 170, title: '创建时间', key: 'createdAt', align: 'center', },
      ],
      params: {
        type: '',
        state: '',
        enabledState: '',
        useState: '',
        name: '',
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods: {}
}
</script>
