<template>
  <Timeline style="padding-top: 5px;">
    <TimelineItem v-for="(item) in rebackList" :key="item.id">
      <Icon type="md-albums" slot="dot" v-if="item.type===3"></Icon>
      <Icon type="md-contact" slot="dot" v-else-if="item.type===2"></Icon>
      <Icon type="ios-home" slot="dot" v-else></Icon>
      <Alert :type="item.type===3?'error':(item.type===2?'warning':'info')" class="reback">
        <div class="content">
          <div class="time">
            <h1 class="h1Title">
              {{ item.title }}
              <Tag :color="item.status===1?'red':'green'" class="status">{{ item.status|getBaseDataName('隐患回复状态') }}</Tag>
            </h1>
          </div>
          <div class="desc">{{ item.desc }}</div>
          <div class="desc">
            <div v-viewer="{url: 'data-src'}">
              <ys-image
                  :radius="3"
                  v-for="subItem in (item.fileList||[])"
                  :key="subItem.id"
                  :src="config.baseURL + '/resource/GetFile/'+subItem.id+'?size=100'"
                  :data-src="config.baseURL + '/resource/GetFile/'+subItem.id"
                  width="50"
                  height="50">
              </ys-image>
            </div>
          </div>
          <div class="createTime">
            <Icon type="ios-contact-outline" size="15" style="margin:0 3px;"/>
            {{ item.createdUser }}&nbsp;&nbsp;
            <Icon type="ios-clock-outline" size="15" style="margin-right: 5px;"/>
            {{ item.createdAt }}
          </div>
          <!--            <div class="opreationBtn">-->
          <!--              <ButtonGroup>-->
          <!--                <Button size="small" icon="md-create" ghost type="primary" @click="edit(index)">修改</Button>-->
          <!--                <Button size="small" icon="md-close" ghost type="primary" @click="delItem(index)">删除</Button>-->
          <!--              </ButtonGroup>-->
          <!--            </div>-->
        </div>
      </Alert>
    </TimelineItem>
    <TimelineItem v-if="rebackList.length===0">
      <Icon type="ios-remove-circle" slot="dot"/>
      <Alert v-if="rebackList.length===0">还没有整改回复记录！</Alert>
    </TimelineItem>
  </Timeline>
</template>

<script>
import {getProblemsRebackList} from "@/api/task";
import config from '@/config/index'

export default {
  name: 'read-list',
  props: {
    checkedData: {
      type: Object, default: () => {
        return {}
      }
    },
    midData: {
      type: Array, default: () => []
    }
  },
  data() {
    return {
      rebackList: [],
      modShow: false,
    }
  },
  computed: {
    config() {
      return config;
    }

  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      getProblemsRebackList({checkCommentId: this.midData.length > 0 ? this.midData[0].id : ''}).then((res) => {
        this.rebackList = res.data || []
      })
    }
  }
}
</script>

<style scoped lang="less">
.ivu-alert {
  margin-bottom: 0;
}

.ivu-timeline-item {
  padding-bottom: 0 !important;
}

.reback {
  padding: 8px 16px 8px 16px
}

.content {


  .time {
    padding-bottom: 5px;
    .h1Title {
      position: relative;

      .status {
        position: absolute;
        right: 0;
        top: 3px;
      }
    }
  }

  .title {
    padding-bottom: 10px;
  }

  .desc {
    line-height: 22px;
    padding-bottom: 5px;
  }
  .createTime {
    color: #959595;
    border-top: 1px solid #e0e0e0;
    padding-top: 8px;
    font-size: 12px;
  }
  .opreationBtn {
    border-top: 1px solid #ececec;
    padding-top: 5px;
  }

  h1.h1Title {
    .otherInfo {
      float: right;
      font-size: 14px;
      font-weight: normal;
    }
  }
}


</style>