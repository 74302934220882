<template>
  <div>
    <Form ref="form" class="editForm " :model="form" :rules="ruleValidate" :label-width="70">
      <FormItem label="经纬度" prop="lngLat">
        <Input v-model="form.lngLat" @on-change="lngLatChange" placeholder="经纬度"></Input>
      </FormItem>
      <FormItem label="地址" prop="address" style="width: 100%">
        <Input v-model="form.address" type="textarea" maxlength="128" show-word-limit style="width: 100%" :autosize="{minRows: 3,maxRows: 5}" placeholder="请输入地址"></Input>
      </FormItem>
    </Form>
    <div class="modalEditBtnArea">
      <Button type="primary" @click="ok('/check/SignIn')">确定并保存</Button>
      <Button @click="close">取消</Button>
    </div>
  </div>
</template>

<script>
import edit from '@/mixins/edit'

export default {
  name: 'status',
  mixins: [edit],
  props: {
    status: {type: Number, default: null}
  },
  data() {
    return {
      form: {
        id: '',
        lngLat: '',
        userId: '',
        lat: '',
        lng: '',
        address: '',
      }
    }
  },
  mounted() {
    if (this.midData.length === 1) {
      this.form.id = this.midData[0].checkId;
      this.form.userId = this.midData[0].userId;
    }
  },
  methods: {
    lngLatChange() {
      if (this.form.lngLat) {
        this.form.lng = this.form.lngLat.split(',')[1]
        this.form.lat = this.form.lngLat.split(',')[0]
      }
    },
  }
}
</script>
<style lang="less" scoped>
</style>
