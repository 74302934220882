import {post, get} from 'ys-admin'


/**
 * 查询专家列表
 * @param {Object} params
 * @returns {Object}
 */
export const expertList = function (params) {
  return get('/expert/List', params)
};

/**
 * 查询打卡详情
 * @param {Object} params
 * @returns {Object}
 */
export const expertConfigInfo = function (params) {
  return get('dynamicCheck/expertConfig/GetById', params)
};
/**
 * 查询专家列表
 * @param {Object} params
 * @returns {Object}
 */
export const checkExpertList = function (params) {
  return get('/checkUser/List', params)
};


/**
 * 查询打卡详情
 * @param {Object} params
 * @returns {Object}
 */
export const checkRecordInfo = function (params) {
  return get('/checkUser/record/GetInfo', params)
};


/**
 * 查询专家详情
 * @param {Object} params
 * @returns {Object}
 */
export const expertInfo = function (params) {
  return get('expert/GetInfo', params)
};


/**
 * 专家启用/停用
 * @param {Number} type
 * @param {Object} params
 * @returns {Object}
 */
export const expertStatus = function (type, params) {
  return post(type === 1 ? 'expert/Enable' : 'expert/Disable', params)
};



/**
 * 专家绑定/解绑
 * @param {Number} type
 * @param {Object} params
 * @returns {Object}
 */
export const expertBind = function (params) {
  return post('/expert/Bind-handle', params)
};
